.queue-sub-nav {
    background-color: white;
    width: 100%;
}

.queue-sub-nav .nav-item .nav-link {
    font-family: 'Inter', sans-serif;
    color: #2B404A;
}

.queue-sub-nav .nav-item .active {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.drug-queue-list h1 {
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    color: #2B404A;
}

.queue-sub-nav .btn-publish {
    background-color: #043750;
    border-color: #043750;
    color: white;
}

