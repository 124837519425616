.top-nav {
    background: #043750;
}

.top-nav .reLogo {

}

.top-nav .logoText {
    color: white;
    display: inline-block;
    border-left: 2px solid rgba(255, 255, 255, 0.24);
    margin-left: 20px; padding-left: 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.top-nav .dropdown-toggle.nav-link {
    color: white;
}

.top-nav .user-initials {
    color: white;
    display: inline-block;
    padding: 10px;
    background-color: #1d4a66;
    border-radius: 100%;
    font-weight: bold;
}