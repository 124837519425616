.drug-search form {
    background: #F1F6F9;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 4px;
    padding: 30px;
}

.drug-search .btn-search {
    background-color: #043750;
    border-color: #043750;
    color: white;
}
