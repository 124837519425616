body {
    font-family: 'Iter', sans-serif;
}

body.login-page {
    background: #043750 !important;
}

.background-reformulary.light {
    background-size: 180% auto;
    background-image: url('/public/reformulary_text_light.svg');
}

.login label {
    display: block;
    color: #2B404A;
    font-weight: 600;
    font-size: 0.8em;
    width: 100%;
}

.login h1 {
    color: #2B404A;
    font-family: 'Iter', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
}

.login button {
    background: #043750;
    color: white;
    width: 100%;
    font-family: 'Iter', sans-serif;
    font-weight: 600;
}

.login hr {
    width: 120px;
}

.login form {
    background: white;
    padding: 24px;
    border-radius: 4px;
}