
.home .item {
    cursor: pointer;
    background: white;
    border: 1px solid #F8FBFC;
    font-family: 'Inter', sans-serif;
    color: #54656D;
}

.home .item .title {
    font-weight: 600;
}

.home .item .aside {
    font-size: 0.8em;
}


