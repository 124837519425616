.side-nav {
    height: 100vw;
    background: #F1F6F9;
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.12);
    display: block;
}

.side-nav .nav-item {
    display: block;
}

.side-nav .nav-item .nav-link {
    font-family: 'Inter';
    color: #2B404A;
}

.side-nav .nav-item .icon {

}

.side-nav .nav-item .active {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

/* .side-nav hr {
    width: 200px;
} */