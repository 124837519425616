
.home .item {
    cursor: pointer;
    background: white;
    border: 1px solid #F8FBFC;
    font-family: 'Inter', sans-serif;
    color: #54656D;
}

.label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.formgroup {
    background: #FFFFFF;
    padding: 24px;
/* Other/Light/100 - Outline */
border: 1px solid rgba(0, 0, 0, 0.12);
box-sizing: border-box;
border-radius: 4px;
    
}

.textbox {
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 4px;
}

