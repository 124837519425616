.drug-info {
    background: white;
    padding: 15px 30px;
    font-family: 'Iter', sans-serif;
    font-size: 12px;
    color: #2B404A;
}

.drug-info .din a {
    font-weight: 600;
    font-size: 14px;
    color: black;
}

.drug-info .name {
    font-size: 14px;
}

.drug-info .active-ingredient {
    text-transform: uppercase;
}

.drug-info .schedule {
    font-size: 12px;
}

.drug-info .actionsList {
    background: #F1F6F9;
    border: 1px solid #B0BAC4;
    border-radius: 4px;
    padding: 10px 10px;
    width: 140px;
    margin-left:auto;
}

.drug-info .badge {
    color: #043750;
    background: #F1F6F9;
    border: 1px solid #B0BAC4;
    margin-left: 5px;
}

.drug-info .actionsList button {
    border: 0;
    background:0;
}
